<template>
  <b-card title="Hoşgeldiniz">
    <b-row>
      <b-col cols="12">
        Ajans Bulut içerik yönetim sistemine hoşgeldiniz.
        <div class="text-muted font-small-2">
          Ajans Bulut CMS
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard,
  BCol,
  BRow,
} from 'bootstrap-vue'

export default {
  name: 'Manager',
  components: {
    BCard,
    BRow,
    BCol,
  },
}
</script>
